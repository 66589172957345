

































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SButton extends Vue {
  @Prop({ default: 'blue' })
  private color: string

  @Prop({ default: false })
  private disabled: boolean

  private onClick(event: Event): void {
    if (!this.disabled) {
      this.$emit('click')
    }
  }
}
