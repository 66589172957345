<template>
  <div>
    <v-container fluid>
      <v-dialog v-model="dialog.changePassword" max-width="350">
        <v-card class="sweeep-dialog">
          <v-card-title>{{
            $t('message.user_setting_changepassword')
          }}</v-card-title>
          <v-card-text class="px-5">
            <v-form ref="form_reset" v-model="form_2.valid">
              <v-text-field
                v-model="form_2.current_password"
                :label="$t('message.user_setting_current_password')"
                type="password"
                dense
                outlined
                filled
                required
              />
              <v-text-field
                v-model="form_2.new_password"
                label="新パスワード"
                :rules="form_2.passwordRules"
                type="password"
                dense
                outlined
                filled
                required
              />
              <v-text-field
                v-model="form_2.repeat_new_password"
                type="password"
                label="新パスワード（確認）"
                :rules="form_2.passwordRules_2"
                dense
                outlined
                filled
                required
              />
              <label align="center" v-if="showError" style="color: red">
                {{ $t('message.signup_confrim_password_match_error') }}
              </label>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click.native="dialog.changePassword = false">
              {{ $t('message.modal_cancel_btn') }}
            </v-btn>
            <v-btn @click="resetPassword()">
              {{ $t('message.modal_save_btn') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog.changeEmail" max-width="350">
        <v-card class="sweeep-dialog">
          <v-card-title>{{
            $t('message.user_setting_changeEmail')
          }}</v-card-title>
          <v-card-text class="px-5">
            <v-form ref="email_reset" v-model="form_3.valid" @submit.prevent>
              <v-text-field
                v-model="form_3.new_email"
                label="新しいメールアドレス"
                :rules="form.emailRules"
                type="email"
                dense
                outlined
                filled
                required
              />
              <label align="center" v-if="showError" style="color: red">
                {{ $t('message.signup_confrim_password_match_error') }}
              </label>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click.native="dialog.changeEmail = false">
              {{ $t('message.modal_cancel_btn') }}
            </v-btn>
            <v-btn
              v-if="!loadingEmailReset"
              @click="resetEmail()"
              color="primary"
            >
              変更
            </v-btn>
            <v-btn v-else loading color="primary darken-1" />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog.emailResetWarning" max-width="650">
        <v-card class="sweeep-dialog">
          <v-card-title>{{ $t('message.confirm') }}</v-card-title>
          <v-card-text
            >メールアドレスの変更はまだ終了していません。</v-card-text
          >
          <v-card-text
            ><p>変更後のメールアドレスにメールを送信しました。</p>
            <p>
              1時間以内に添付URLをクリックして変更手続きをお済ませ下さい。
            </p></v-card-text
          >
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog.emailResetWarning = false"> 閉じる </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card flat class="pa-4 flex lg5 md8">
        <v-form class="" v-model="form.valid" ref="form" lazy-validation>
          <v-layout>
            <v-flex xs9>
              <v-text-field
                v-model="form.full_name"
                label="氏名"
                placeholder=" "
                :rules="form.nameRules"
                required
              />
            </v-flex>
            <v-flex xs3>
              <s-button class="tw-mt-5" :disabled="!form.valid" @click="submit">
                {{ $t('message.submit') }}
              </s-button>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs9>
              <v-text-field
                v-model="form.email"
                label="メールアドレス"
                hint="例: sample@automationlab.co"
                placeholder=" "
                :rules="form.emailRules"
                validate-on-blur
                disabled
              />
            </v-flex>
            <v-flex xs3>
              <v-btn
                @click.native.stop="dialog.changeEmail = true"
                small
                color=""
              >
                {{ $t('message.user_setting_changeEmail') }}
              </v-btn>
              <v-btn
                @click.native.stop="dialog.changePassword = true"
                small
                color=""
              >
                {{ $t('message.user_setting_changepassword') }}
              </v-btn>
            </v-flex>
          </v-layout>
          <!-- <v-btn @click="clear" color="primary">{{$t("message.clear")}}</v-btn> -->
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import SButton from '@/components/Button/SButton'

export default {
  components: {
    SButton,
  },
  data() {
    var t = this
    return {
      dialog: {
        changePassword: false,
        changeEmail: false,
        emailResetWarning: false,
      },
      user_id: '',
      organization_id: '',
      showError: false,
      loadingEmailReset: false,
      form: {
        valid: true,
        full_name: '',
        date_created: '',
        company_name: '',
        industry_id: '',
        nameRules: [
          (v) => !!v || t.$t('message.form_input_required'),
          (v) =>
            (v && v.replace(/\s/g, '').length >= 1) ||
            t.$t('message.form_input_required'),
          (v) => v.length <= 50 || '50' + t.$t('message.character_max_length'),
        ],
        contact_number_list: [],
        email: '',
        full_name_rules: [(v) => !!v || '必ず入力ください'],
        emailRules: [
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              v
            ) || '正しい形式で入力ください',
        ],
        select: null,
        items: ['業種1', '業種2', '業種3', '業種4'],
        items_industry_list: [],
        items_settlement_day: [],
        items_settlement_month: [],
        checkbox: false,
        zip: '',
        address: '',
        settlement_day: '',
        settlement_month: '',
      },
      form_2: {
        valid: true,
        new_password: '',
        repeat_new_password: '',
        current_password: '',

        passwordRules: [
          (v) => !!v || '※この項目は必須です',
          (v) =>
            (v &&
              v.length >= 8 &&
              this.isIncludeUpper(v) &&
              this.isIncludeLower(v) &&
              this.isIncludeNumber(v) &&
              this.isIncludeSymbol(v)) ||
            t.$t('message.password_validation'),
          (v) =>
            (v && v.length <= 50) ||
            '50' + t.$t('message.character_max_length'),
        ],
        passwordRules_2: [
          (v) => !!v || '※この項目は必須です',
          (v) =>
            (v &&
              v.length >= 8 &&
              this.isIncludeUpper(v) &&
              this.isIncludeLower(v) &&
              this.isIncludeNumber(v) &&
              this.isIncludeSymbol(v)) ||
            t.$t('message.password_validation'),
          (v) =>
            (v && v.length <= 50) ||
            '50' + t.$t('message.character_max_length'),
          (v) =>
            (v && v == this.form_2.new_password) ||
            t.$t('message.error_password_and_confirm_not_same'),
        ],
      },
      form_3: {
        valid: true,
        new_email: '',
        emailRules: [
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              v
            ) || '正しい形式で入力ください',
        ],
      },
    }
  },
  mounted() {
    for (var month = 1; month <= 12; month++) {
      var toAdd = {
        month: month,
        name: month + '月',
      }
      this.form.items_settlement_month.push(toAdd)
    }
    for (var day = 1; day <= 31; day++) {
      var toAdd = {
        day: day,
        name: day + '日',
      }
      this.form.items_settlement_day.push(toAdd)
    }
    this.confirmResetEmailStatus()

    let url = this.$store.getters.apiUserSessionFunction
    url += '?user_organization_id=' + this.$store.getters.getUserOrganizationID

    const auth_token = this.$store.getters.getAuthToken

    axios
      .get(url, {
        headers: {
          Authorization: auth_token,
        },
      })
      .then((response) => {
        if (response.data.status == 'success') {
          const user = response.data.user
          const userRole = response.data.user_role
          const organization = response.data.organization
          const industry = response.data.industry
          const excludeCompanyNames = response.data.exclude_company_names
          this.user_id = user.id
          this.organization_id = organization.id
          this.form.company_name = organization.name
          this.form.full_name = user.full_name
          this.form.email = user.email
          this.form.date_created = user.date_created
          this.form.zip = organization.zipcode
          this.form.address = organization.address
          this.form.contact_number_list = organization.contact_number_list
          this.form.settlement_day = parseInt(organization.settlement_day)
          this.form.industry_id = organization.industry_id
          this.form.settlement_month = parseInt(organization.settlement_month)
          this.form.items_industry_list = industry
        } else {
          Snackbar.error(response.data.error)
        }
      })
  },
  methods: {
    changeMonth() {},
    isIncludeUpper(text) {
      for (let s of text) {
        if (s.match(/^[A-Z]+$/)) {
          return true
        }
      }
      return false
    },
    isIncludeLower(text) {
      for (let s of text) {
        if (s.match(/^[a-z]+$/)) {
          return true
        }
      }
      return false
    },
    isIncludeNumber(text) {
      for (let s of text) {
        if (!isNaN(s)) {
          return true
        }
      }
      return false
    },
    isIncludeSymbol(text) {
      let reg = new RegExp(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g)
      if (reg.test(text)) {
        return true
      }
      return false
    },
    submit() {
      if (this.$refs.form.validate()) {
        const apiUrl = this.$store.getters.apiUserSessionFunction
        const data = {
          user_id: this.user_id,
          full_name: this.form.full_name,
          company_name: this.form.company_name,
          zip: this.form.zip,
          address: this.form.address,
          contact_number_list: this.form.contact_number_list,
          industry_id: this.form.industry_id,
          settlement_day: this.form.settlement_day,
          settlement_month: this.form.settlement_month,
          organization_id: this.organization_id,
          auth_token: this.$store.getters.getAuthToken,
          type: 'user',
        }
        this.$store
          .dispatch('editSession', {
            data,
            apiUrl,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              Snackbar.success(this.$t('message.success'))
            }
          })
      }
    },
    resetPassword() {
      this.showError = false
      if (this.$refs.form_reset.validate()) {
        if (this.form_2.new_password == this.form_2.repeat_new_password) {
          const apiUrl = this.$store.getters.apiPasswordInstanceFunction
          const data = {
            current_password: this.form_2.current_password,
            new_password: this.form_2.new_password,
            confirm_new_password: this.form_2.repeat_new_password,
            auth_token: this.$store.getters.getAuthToken,
          }
          this.$store
            .dispatch('sessionResetPassword', {
              data,
              apiUrl,
            })
            .then((response) => {
              if (response.data.error) {
                this.$refs.form_reset.reset()
              }
              if (response.data.status == 'success') {
                Snackbar.success(
                  this.$t('message.user_setting_success_password')
                )
                this.$refs.form_reset.reset()
                this.dialog.changePassword = false
                this.showError = false
              } else {
              }
            })
        } else {
          this.showError = true
        }
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    resetEmail() {
      this.loadingEmailReset = true
      if (this.$refs.email_reset.validate()) {
        Axios.post(
          this.$store.getters.apiEmailResetFunction,
          {
            new_mail: this.form_3.new_email,
            user_id: this.$store.getters.getUserID,
          },
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        ).then((response) => {
          if (response.data.status == 'success') {
            this.loadingEmailReset = false
            this.dialog.changeEmail = false
            this.dialog.emailResetWarning = true
          } else {
            this.loadingEmailReset = false
            this.dialog.changeEmail = false
            const error = response.data.error
            const errorMessages = {
              email_exists: 'すでに登録されているメールアドレスです。',
            }
            if (errorMessages[error]) {
              Snackbar.error(errorMessages[error])
            } else {
              Snackbar.error('不明なエラー ' + error)
            }
          }
        })
      }
    },
    confirmResetEmailStatus() {
      switch (this.$route.query.email_change) {
        case undefined:
          break
        case 'success':
          Snackbar.success('メールアドレスの変更が完了しました')
          break
        case 'expired':
          Snackbar.error(
            'URLの有効期限が切れています。\n再度画面からメールアドレスの変更を行ってください。'
          )
          break
        case 'failure':
          Snackbar.error('エラーが発生しました')
          break
      }
    },
  },
}
</script>
